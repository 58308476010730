jQuery( document ).ready(function($) {

  // mobile menu
  $('.menu-trigger').click(function(){
      $(this).toggleClass('active')
      $('.menu-main-container').slideToggle()
  })

  // hero slider
  $('.hero-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>',
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    fade: true,
    cssEase: 'linear'
  });

  // recent posts slider
  $('.recent-articles').slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
        }
      }
    ]
  });

  // recent posts slider
  $('.testimonios-slider').slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
        }
      }
    ]
  });

  // accordion
  $('.question .title').click(function(){
      $(this).toggleClass('active')
      $(this).parent().find('.answer').slideToggle()
  })

  $('.accordion-item .inner-title').click(function(){
    $(this).toggleClass('active')
    $(this).parent().toggleClass('active')
    $(this).parent().find('.inner-content').slideToggle()
})

});